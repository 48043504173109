









































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import { EdgeDevice, Camera } from "@/api/models";
import EdgeDevicesForm from "@/components/forms/EdgeDevicesForm.vue";
import api from "@/api/api";
import { replace } from "lodash";

export default Vue.extend({
  name: "EdgeDevicesTable",

  components: {
    EdgeDevicesForm,
  },

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    showEdgeDevicesForm: false,
    selectedEdgeDevicesDetails: null as EdgeDevice | null,
    edgeDevices: {
      headers: [
        { text: "Device ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Command", value: "command" },
        { text: "Local IP Address", value: "local_ip_address" },
        { text: "Device Status", value: "is_device_offline" },
        { text: "Allxon Alerts", value: "allxon_alerts" },
        { text: "Actions", value: "device_actions" },
      ],
      data: [] as Array<EdgeDevice>,
    },
    showDeleteConfirmDialog: false,
    showToggleRunningConfirmDialog: false,
    showCopyCommandDialog: false,
    edgeSetupCommand: "",
    isUpdatingPlateRecognizerConfig: false,
    updatingPlateRecognizerConfigId: null as string | null,
    replaceEdgeDevice: {
      show: false,
      selectedEdgeDeviceId: null as string | null,
      allCamerasInLot: [] as Array<Camera>,
      cameras: [] as Array<Camera>,
    },
  }),

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "isTechnician"]),
  },

  mounted() {
    console.log("Loading Data for lot Id", this.parkingLotId);
    this.getEdgeDevicesData();
    this.getAllCamerasInLot();
  },

  methods: {
    async getEdgeDevicesData() {
      this.isLoading = true;
      let edgeDevices = await api.getAllEdgeDevices(this.parkingLotId);
      if (edgeDevices !== null) {
        this.edgeDevices.data = edgeDevices;
      } else {
        console.log(
          "Unable to load list of Edge Devices for lot id",
          this.parkingLotId
        );
      }
      this.isLoading = false;
    },
    showConfirmToggleRunning(device: EdgeDevice) {
      console.log("show dialog", device);
      this.selectedEdgeDevicesDetails = device;
      if (device.is_running) {
        this.showToggleRunningConfirmDialog = true;
      } else {
        this.updateDeviceRunning();
      }
    },
    closeConfirmToggleRunning(device: EdgeDevice) {
      this.showToggleRunningConfirmDialog = false;
      this.selectedEdgeDevicesDetails = null;
    },
    async updateDeviceRunning() {
      this.isLoading = true;
      const device = this.selectedEdgeDevicesDetails;
      if (device == null) {
        console.log("No device selected to update");
        throw new Error("No device selected to update");
      }
      const actionText = device.is_running ? "disable" : "enable";
      console.log("Changing device status", !device.is_running, device.id);
      try {
        let edgeDeviceResp = await api.updateEdgeDevice(this.parkingLotId, {
          ...device,
          is_running: !device.is_running,
        });
        if (edgeDeviceResp) {
          this.$dialog.message.info(
            `Edge device AI pipeline ${actionText}d successfully.`,
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        } else {
          throw new Error("Error updating device, null response");
        }
      } catch (error) {
        console.error("Error updating device", error);
        this.$dialog.message.error(
          `Error, unable to ${actionText} edge device AI pipeline.`,
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.isLoading = false;
      this.showToggleRunningConfirmDialog = false;
      this.getEdgeDevicesData(); // Refresh
    },
    async deleteDevice(device: EdgeDevice) {
      this.isLoading = true;
      let edgeDevices = await api.deleteEdgeDevice(
        this.parkingLotId,
        device.id
      );
      this.isLoading = false;
      this.showDeleteConfirmDialog = false;
      this.getEdgeDevicesData(); // Refresh
    },
    showEdgeDevicesDetailsInForm(edgeDevice: EdgeDevice) {
      this.selectedEdgeDevicesDetails =
        this.edgeDevices.data.find((c) => c.id === edgeDevice.id) || null;
      if (this.selectedEdgeDevicesDetails) {
        this.showEdgeDevicesForm = true;
      }
    },
    closeEdgeDevicesForm(show_command: string) {
      this.selectedEdgeDevicesDetails = null;
      this.showEdgeDevicesForm = false;

      if (show_command && show_command != "" && show_command.length > 0) {
        this.edgeSetupCommand = show_command;
        this.showCopyCommandDialog = true;
      }
    },
    closeCopyCommand() {
      this.edgeSetupCommand = "";
      this.showCopyCommandDialog = false;
    },
    openAllxonAlerts(edgeDevice: EdgeDevice) {
      window.open("https://dms.allxon.com/dashboard", "_blank");
    },
    copyToClipboardEdgeCommand(command: string | null) {
      navigator.clipboard.writeText(command ? command : this.edgeSetupCommand);
    },
    getEdgeSetupCommand(name: string) {
      return `sudo bash -c "$(wget -qO- ${process.env.VUE_APP_API_URL.substring(
        0,
        process.env.VUE_APP_API_URL.indexOf("/api")
      )}/edge_onboarding.sh)" -s -n "${name}" -e "${process.env.VUE_APP_ENV}"`;
    },
    async updatePlateRecognizerConfig(device_id: string) {
      this.isUpdatingPlateRecognizerConfig = true;
      this.updatingPlateRecognizerConfigId = device_id;
      let response = await api.updateEdgeDevicePlateRecognizerConfig(
        this.parkingLotId,
        device_id
      );
      if (response) {
        this.$dialog.message.info(
          "Plate Recognizer configuration updated successfully.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.updatingPlateRecognizerConfigId = null;
      this.isUpdatingPlateRecognizerConfig = false;
    },
    async getAllCamerasInLot() {
      console.log("Fetching cameras info");
      let cameras = await api.getAllCameras(this.parkingLotId);
      if (cameras !== null) {
        this.replaceEdgeDevice.allCamerasInLot = cameras.filter(
          (c) =>
            c.is_inference_processing_method == "edge_device" ||
            (c.is_lpr_camera_type != null &&
              c.is_lpr_camera_type == "platerecognizer_lpr")
        );
      } else {
        console.log(
          "Unable to load list of cameras for lot id",
          this.parkingLotId
        );
      }
    },
    // openReplaceEdgeDeviceForm(edge_device_id: string) {
    //   const edgeDevice = this.edgeDevices.data.find(
    //     (item: EdgeDevice) => item.id == edge_device_id
    //   );
    //   if (!edgeDevice) return;

    //   this.replaceEdgeDevice.cameras =
    //     this.replaceEdgeDevice.allCamerasInLot.filter((camera) =>
    //       edgeDevice.camera_ids_assigned_to_device?.includes(camera.id)
    //     );
    //   this.replaceEdgeDevice.selectedEdgeDeviceId = edge_device_id;
    //   this.replaceEdgeDevice.show = true;
    // },
    closeReplaceEdgeDeviceForm() {
      this.replaceEdgeDevice.selectedEdgeDeviceId = null;
      this.replaceEdgeDevice.show = false;
    },
  },

  watch: {
    /**
     * Reset the EdgeDevicesForm whenever its dialog is hidden/closed.
     */
    showEdgeDevicesForm(showingForm) {
      if (!showingForm) {
        (this.$refs.edgeDevicesForm as any).resetForm();
      }
    },
  },
});
