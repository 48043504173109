var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.networkDeviceId == null ? "New Network Device" : ("Network Device ID " + _vm.networkDeviceId)))]),_c('v-card-text',[_c('v-form',{ref:"networkDeviceFormElm",model:{value:(_vm.allFieldsValid),callback:function ($$v) {_vm.allFieldsValid=$$v},expression:"allFieldsValid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Name","hint":"Name of the Network Device","rules":[
                function (v) { return (v && v.length >= 3 && v.length < 50) ||
                  'Name must be between 3 to 50 characters long.'; },
                _vm.validString ],"clearable":"","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":['Router', 'Switch', 'WiFi Access Point'],"label":"Device Type","clearable":"","hide-no-data":""},model:{value:(_vm.deviceType),callback:function ($$v) {_vm.deviceType=$$v},expression:"deviceType"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Local IP Address","value":_vm.localIpAddress,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 7 && v.length < 16) ||
                  'IP Address must be min 7 and max 15 chars long.'; } ],"clearable":""},on:{"input":function($event){_vm.localIpAddress = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Public IP Address","value":_vm.publicIpAddress,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 7 && v.length < 16) ||
                  'IP Address must be min 7 and max 15 chars long.'; } ],"clearable":""},on:{"input":function($event){_vm.publicIpAddress = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Mac Address","value":_vm.macAddress,"rules":[_vm.isValidMACAddress],"clearable":""},on:{"input":function($event){_vm.macAddress = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Manufacturer","hint":"Manufacturer of the Network Device","value":_vm.manufacturer,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 3 && v.length < 50) ||
                  'Manufacturer name must be between 3 to 50 characters long.'; },
                _vm.validString ]},on:{"input":function($event){_vm.manufacturer = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Model","hint":"Model of the Network Device","value":_vm.model,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 3 && v.length < 50) ||
                  'Model name must be between 3 to 50 characters long.'; },
                _vm.validString ]},on:{"input":function($event){_vm.model = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"4"}},[_c('span',{staticClass:"text-body-1"},[_vm._v("Location: ")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Latitude","required":"","value":_vm.location_lat,"type":"number","step":"0.001"},on:{"input":function($event){_vm.location_lat = $event !== '' ? $event : null},"focus":function($event){return $event.target.select()}}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Longitude","required":"","value":_vm.location_lng,"type":"number","step":"0.001"},on:{"input":function($event){_vm.location_lng = $event !== '' ? $event : null},"focus":function($event){return $event.target.select()}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Network Device Comment (Optional)","hint":"Max characters allowed is 512","rules":[
                function (v) { return v == null ||
                  v == '' ||
                  (v && v.length <= 512) ||
                  'Comment should not exceed 512 characters.'; } ],"auto-grow":"","rows":"1","autocomplete":"","clearable":"","counter":"","filled":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":"Status of the Network Device","inset":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeForm}},[_vm._v("Close")]),_c('v-btn',{attrs:{"disabled":_vm.name.length < 3 || (_vm.comment != null && _vm.comment.length > 512),"color":"primary","text":""},on:{"click":_vm.submitForm}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }