var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getEdgeDevicesData}},[_vm._v("Refresh")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex aligh-start justify-end"},[_c('v-dialog',{attrs:{"max-width":"600px","fullscreen":_vm.$vuetify.breakpoint.smAndDown},on:{"click:outside":_vm.closeEdgeDevicesForm,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeEdgeDevicesForm.apply(null, arguments)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Add Edge Device")])]}}]),model:{value:(_vm.showEdgeDevicesForm),callback:function ($$v) {_vm.showEdgeDevicesForm=$$v},expression:"showEdgeDevicesForm"}},[(_vm.showEdgeDevicesForm)?_c('edge-devices-form',{ref:"edgeDevicesForm",attrs:{"parkingLotId":_vm.parkingLotId,"existingEdgeDeviceDetails":_vm.selectedEdgeDevicesDetails,"needsInit":_vm.showEdgeDevicesForm},on:{"close-form":_vm.closeEdgeDevicesForm,"refresh-data":_vm.getEdgeDevicesData}}):_vm._e()],1)],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.edgeDevices.headers,"items":_vm.edgeDevices.data,"items-per-page":10},on:{"click:row":_vm.showEdgeDevicesDetailsInForm},scopedSlots:_vm._u([{key:"item.edge_device_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.edge_device_id || "Not Set")+" ")]}},{key:"item.command",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncateLines")(_vm.getEdgeSetupCommand(item.name)))+" "),_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.copyToClipboardEdgeCommand(_vm.getEdgeSetupCommand(item.name))}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}},{key:"item.is_device_offline",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"status-icon ml-4",attrs:{"small":"","color":item && item.is_device_offline ? 'red' : 'green'}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item && item.is_device_offline ? "Device Offline" : "Device Online"))])])]}},{key:"item.allxon_alerts",fn:function(ref){
var item = ref.item;
return [(item.allxon_device_id)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.openAllxonAlerts(item)}}},[_vm._v("0")]):_vm._e()]}},{key:"item.device_actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center"}},[(_vm.isSuperAdmin)?_c('v-menu',{attrs:{"offset-x":"","close-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dense":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(
                    item.is_running &&
                    item.has_active_plate_recognizer_camera != null &&
                    item.has_active_plate_recognizer_camera.every(Boolean)
                  )?_c('v-list-item',{on:{"click":function($event){return _vm.updatePlateRecognizerConfig(item.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Update Plate Recognizer Configuration")])],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)]}}],null,true),model:{value:(_vm.showDeleteConfirmDialog),callback:function ($$v) {_vm.showDeleteConfirmDialog=$$v},expression:"showDeleteConfirmDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Confirm Delete")]),_c('v-card-text',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"size":"80","color":"warning darken-2"}},[_vm._v("mdi-alert")])],1),_c('v-col',[_c('div',{staticClass:"v-block"},[_vm._v(" Are you sure you would like to delete Edge Device \""+_vm._s(item.id)+"\" ? ")])])],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showDeleteConfirmDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteDevice(item)}}},[_vm._v(" Yes, Delete it! ")])],1)],1)],1)],1)],1):_vm._e(),_c('v-switch',{attrs:{"inset":"","dense":"","input-value":item.is_running},on:{"click":function($event){$event.stopPropagation();return _vm.showConfirmToggleRunning(item)}}}),(
                _vm.isUpdatingPlateRecognizerConfig &&
                item.id === _vm.updatingPlateRecognizerConfigId
              )?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"32"}}):_vm._e()],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.showToggleRunningConfirmDialog),callback:function ($$v) {_vm.showToggleRunningConfirmDialog=$$v},expression:"showToggleRunningConfirmDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Confirm Disable Edge Device")]),_c('v-card-text',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"size":"80","color":"warning darken-2"}},[_vm._v("mdi-alert")])],1),_c('v-col',[_c('div',{staticClass:"v-block"},[_vm._v(" Are you sure you would like to disable Edge Device \""+_vm._s((_vm.selectedEdgeDevicesDetails && _vm.selectedEdgeDevicesDetails.id) || null)+"\" ? ")])])],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeConfirmToggleRunning}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.updateDeviceRunning}},[_vm._v(" Yes, Disable it! ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.showCopyCommandDialog),callback:function ($$v) {_vm.showCopyCommandDialog=$$v},expression:"showCopyCommandDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Edge Setup Command")]),_c('v-card-text',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"size":"80","color":"success darken-2"}},[_vm._v("mdi-command")])],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Edge setup command","hint":"Execute the command to setup Edge device","value":_vm.edgeSetupCommand,"persistent-hint":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.copyToClipboardEdgeCommand(_vm.edgeSetupCommand)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v("Copy to Clipboard")])])],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeCopyCommand}},[_vm._v("Close")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.replaceEdgeDevice.show),callback:function ($$v) {_vm.$set(_vm.replaceEdgeDevice, "show", $$v)},expression:"replaceEdgeDevice.show"}},[_c('v-card',[_c('v-card-title',[_vm._v("Replace Edge Device")]),_c('v-card-text',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',{staticClass:"text-subtitle-1"},[_vm._v("Select Cameras:")]),_c('v-card-text',[_c('v-list',_vm._l((_vm.replaceEdgeDevice.cameras),function(camera){return _c('v-list-item',{key:("replace-edge-camera-" + (camera.id))},[_c('v-list-item-content',[_c('v-checkbox',{attrs:{"label":("" + (camera.name))}})],1)],1)}),1)],1)],1),_c('v-card',{staticClass:"mt-4",attrs:{"outlined":""}},[_c('v-card-subtitle',{staticClass:"text-subtitle-1"},[_vm._v("Select Display Boards:")])],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""}},[_vm._v("Create New Edge Device")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeReplaceEdgeDeviceForm}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }