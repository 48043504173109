import { LicensePlateRecognitionHistory } from "./LicensePlateRecognitionHistory";
import { ParkingHistory } from "./ParkingHistory";
import { ParkingSpotSavedEndUserDetails } from "./ParkingLot";

export enum VehicleParkingUsageTrackingRecordState {
  active = "active",
  closed = "closed",
  invalidated = "invalidated",
}

export interface VehicleParkingUsageTrackingAssociationBase {
  license_plate_detected: string;
  parking_lot_id: number;
  parking_spot_id: number | null;
  parking_zone_id: number | null;
  special_area_id: number | null;

  lot_usage_time: number;
  parking_usage_time: number;

  lpr_entry_record_id: number | null;
  lpr_exit_record_id: number | null;
  parking_history_record_ids: Array<number> | null;
}

export interface VehicleParkingUsageTrackingAssociation
  extends VehicleParkingUsageTrackingAssociationBase {
  id: number;
  created_at: string;
  updated_at: string | null;
  is_verified: boolean;
  is_complete: boolean;
  record_state: VehicleParkingUsageTrackingRecordState;
  is_ai_matched_low_confidence: boolean;
}

export interface VehicleParkingUsageTrackingAssociationDetails
  extends VehicleParkingUsageTrackingAssociation {
  verified_by_email: string;
  lpr_entry_record: LicensePlateRecognitionHistory;
  lpr_exit_record: LicensePlateRecognitionHistory;
  lpr_history_records: Array<LicensePlateRecognitionHistory> | null;
  parking_history_records: Array<ParkingHistory>;
  saved_by_end_user: ParkingSpotSavedEndUserDetails | null;
}
