



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { OrganizationUserType } from "@/api/models";
import OrgUserForm from "@/components/forms/OrgUserForm.vue";
import ParkingLotForm from "@/components/forms/ParkingLotForm.vue";
import OperatorContactForm from "@/components/forms/OperatorContactForm.vue";
import ConfigureNVRForm from "@/components/forms/ConfigureNVRForm.vue";
import OrgUserAuthorizationForm from "@/components/forms/OrgUserAuthorizationForm.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import dayjs from "dayjs";

export default Vue.extend({
  name: "App",
  components: {
    OrgUserForm,
    ParkingLotForm,
    ConfigureNVRForm,
    OrgUserAuthorizationForm,
    OperatorContactForm,
  },

  data: () => ({
    lotId: null as number | null,
    showDrawer: true,
    showLotDrawer: true,
    showChangePasswordForm: false,
    showEmailPreferencesForm: false,
    showParkingLotForm: false,
    showConfigureNVRForm: false,
    currentParkingLotName: null as string | null,

    maintenanceOngoingInterval: null as number | null,

    showAuthorizationForm: false,

    showLotOperatorContactForm: false,
    dashboardPath: process.env.VUE_APP_3_BASE_URL_PATH || "/",
  }),

  computed: {
    ...mapGetters("user", [
      "isLoggedIn",
      "currentUserEmail",
      "isSuperAdminViewSwitched",
      "isSuperAdmin",
      "isAdmin",
      "isOperator",
      "isTechnician",
      "isDeveloper",
      "hasAccessLevelDashboardMonitoring",
      "hasAccessLevelApiConfiguration",
      "hasAccessLevelCameraEditing",
      "getCurrentUserData",
      "isUpcomingOngoingMaintenanceWindow",
      "upcomingMaintenanceWindowStartAtLocalTime",
      "isMaintenanceOngoing",
    ]),
    ...mapGetters("data", ["getCurrentParkingLotData"]),

    nameInitials() {
      if (!this.getCurrentUserData) return "";

      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
      let initials = [...this.getCurrentUserData.name.matchAll(rgx)] || [];
      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();

      return initials;
    },
    currentRoute() {
      return this.$route.name;
    },
    isLotRoute() {
      return this.$route.name?.startsWith("Lot");
    },
    userRole() {
      if (this.getCurrentUserData) {
        switch (this.getCurrentUserData.user_type) {
          case OrganizationUserType.superadmin:
            return "Super Admin";
          case OrganizationUserType.admin:
            return "Admin";
          case OrganizationUserType.operator:
            return "Operator";
          case OrganizationUserType.technician:
            return "Technician";
          case OrganizationUserType.developer:
            return "Developer";
          default:
            return null;
        }
      }
      return null;
    },
  },

  created() {
    if (this.$route.params && this.$route.params.lotId) {
      this.lotId = Number(this.$route.params.lotId);
    }
  },

  async mounted() {
    // Check if the token from a previous session is saved in localstorage, and try to use it
    const token = localStorage.getItem("token");
    if (token) {
      this.loginSuccess(token);
      this.initCurrentOrgUser();
    } else {
      this.initMaintenanceWindow();
      if (
        this.$route.name !== "Login" &&
        this.currentRoute != "ShowMap" &&
        this.currentRoute != "ShowMapImage" &&
        this.currentRoute != "LotTrends"
      ) {
        // Redirect to Login page if there is no saved token and user is not on Login page already
        this.$router.push({ name: "Login" });
      }
    }

    window.addEventListener("storage", () => {
      const token = localStorage.getItem("token");
      // if token cleared refresh page
      if (!token) {
        this.$router.go(0);
      }
    });

    if (this.getCurrentParkingLotData) {
      this.currentParkingLotName = this.getCurrentParkingLotData.name;
    } else if (localStorage.getItem("currentLotName")) {
      // First check if lot name is right for lot id
      let lotNames = localStorage.getItem("lotNames");
      if (lotNames && this.lotId) {
        let lotNamesObj = JSON.parse(lotNames);
        if (lotNamesObj[this.lotId]) {
          this.currentParkingLotName = lotNamesObj[this.lotId];
        }
      }
      // this.currentParkingLotName = localStorage.getItem("currentLotName");
    }
    window.addEventListener("lot-name-changed", (event: any) => {
      if (event.detail.lot_name) {
        this.currentParkingLotName = event.detail.lot_name;
      } else {
        this.currentParkingLotName = null;
      }
    });

    // check if trends page using path and check if options param in path is true
    if (this.$route.path.includes("dashboard") && this.$route.query.options) {
      this.showParkingLotForm = true;
    }
  },

  updated() {
    if (!this.isLoggedIn) {
      this.$router.push({ name: "Login" });
    }
    if (this.$route.params && this.$route.params.lotId) {
      this.lotId = Number(this.$route.params.lotId);
    } else {
      this.lotId = null;
    }
  },

  methods: {
    ...mapMutations("user", [
      "loginSuccess",
      "logout",
      "dismissUpcomingMaintenance",
    ]),
    ...mapActions("user", [
      "initCurrentOrgUser",
      "initMaintenanceWindow",
      "switchToAdminView",
    ]),
  },

  watch: {
    isLoggedIn(newIsLoggedIn) {
      if (
        newIsLoggedIn === false &&
        this.$route.name !== "Login" &&
        this.currentRoute != "ShowMap"
      ) {
        this.$router.push({
          name: "Login",
        });
      }
    },

    isUpcomingOngoingMaintenanceWindow(newIsUpcoming) {
      if (newIsUpcoming && this.maintenanceOngoingInterval === null) {
        const startsAt = dayjs(newIsUpcoming.starts_at).utc(true).local();
        const now = dayjs();
        const pendingMilliSecs = startsAt.diff(now);
        console.log(
          `Maintenance Upcoming in ${pendingMilliSecs} ms, setting interval to check for maintenance window.`
        );
        this.maintenanceOngoingInterval = setInterval(() => {
          // Check Maintenance endpoint again to see maintenance is ongoing
          this.initMaintenanceWindow();
        }, 10000);
      }
    },

    isMaintenanceOngoing(newIsOngoing) {
      console.log("Is maintenance window changed", newIsOngoing);
      if (newIsOngoing === true && this.$route.name !== "MaintenanceOngoing") {
        this.$router.push({ name: "MaintenanceOngoing" });
      } else if (this.$route.name === "MaintenanceOngoing") {
        this.$router.push({ name: "Home" });
      }
    },

    getCurrentParkingLotData(newData) {
      if (newData) {
        this.currentParkingLotName = newData.name;
      }
    },

    showDrawer(newShowDrawer) {
      this.showLotDrawer = newShowDrawer;
    },
  },
});
