import { ParkingLot, SessionData } from "@/api/models";
import api from "@/api/api";

const state = {
  currentParkingLotData: null as ParkingLot | null,
};

const getters = {
  getCurrentParkingLotData(state: SessionData): ParkingLot | null {
    return state.currentParkingLotData;
  },
};

const mutations = {
  setCurrentParkingLotData(
    state: SessionData,
    parkingLotData: ParkingLot
  ): void {
    state.currentParkingLotData = parkingLotData;
  },
};

const actions = {
  async initCurrentParkingLotData(
    context: any,
    parking_lot_data: ParkingLot | null
  ): Promise<void> {
    const parkingLotData: ParkingLot | null = parking_lot_data;
    if (parkingLotData) {
      context.commit("setCurrentParkingLotData", parkingLotData);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
