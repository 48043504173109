












































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

import { ParkingLot } from "@/api/models";
import CamerasTable from "@/components/tables/CamerasTable.vue";
import NvrTable from "@/components/tables/NvrTable.vue";
import ServiceNodeTable from "@/components/tables/ServiceNodeTable.vue";
import NetworkDevicesTable from "@/components/tables/NetworkDevicesTable.vue";
import EdgeDevicesTable from "@/components/tables/EdgeDevicesTable.vue";
import DigitalBoardsTable from "@/components/tables/DigitalBoardsTable.vue";
import api from "@/api/api";

export default Vue.extend({
  name: "Devices",

  components: {
    CamerasTable,
    NvrTable,
    ServiceNodeTable,
    NetworkDevicesTable,
    EdgeDevicesTable,
    DigitalBoardsTable,
  },

  data: () => ({
    lotId: 0,
    tab: "cameras",
    parkingLot: null as ParkingLot | null,
    breadcrumbItems: [
      {
        text: "Devices",
        disabled: true,
      },
      {
        text: "Cameras",
        disabled: true,
      },
    ],
    isExportingData: false,
    isLoading: false,
    parkingZones: [] as {
      text: string;
      value: number;
      parent_zone_id: number | null;
    }[],
  }),

  computed: {
    ...mapGetters("user", [
      "isSuperAdmin",
      "isTechnician",
      "hasAccessLevelCameraEditing",
      "hasAccessLevelDashboardMonitoring",
    ]),
  },

  created() {
    this.lotId = Number(this.$route.params.lotId);
  },

  mounted() {
    window.addEventListener("storage", this.checkForRefresh);
    this.getParkingLot();
  },

  beforeDestroy() {
    window.removeEventListener("storage", this.checkForRefresh);
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),

    async getParkingLot() {
      this.parkingLot = await api.getParkingLot(this.lotId);
      if (this.parkingLot) {
        if (this.parkingLot.parking_zones) {
          this.parkingLot.parking_zones
            .filter((zone) => zone.zone_type != "time_limited_zone")
            .forEach((zone) => {
              this.parkingZones.push({
                text: zone.name != null ? zone.name : `Zone ${zone.id}`,
                value: zone.id,
                parent_zone_id: zone.nested_parent_zone_id,
              });
            });
        }
      }
      this.initCurrentParkingLotData(this.parkingLot);
    },
    checkForRefresh() {
      const refreshPage = localStorage.getItem("refreshPage");
      if (refreshPage && JSON.parse(refreshPage)) {
        console.log("Refreshing page", JSON.parse(refreshPage));
        localStorage.setItem("refreshPage", JSON.stringify(false));
        localStorage.removeItem("refreshPage");
        this.$router.go(0);
      }
    },
    async exportDevicesToXLSX() {
      this.isExportingData = true;
      try {
        this.$dialog.message.info("Exporting Devices data ...", {
          position: "top-right",
          timeout: 3000,
        });
        const downloadSuccessful = await api.exportDevicesData(this.lotId);
        if (downloadSuccessful) {
          this.$dialog.message.info("Exported Devices data successfully.", {
            position: "top-right",
            timeout: 3000,
          });
        } else {
          this.$dialog.message.error(
            "Error, unable to export Devices data. Please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, unable to export Devices data at the moment. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        this.isExportingData = false;
      }
    },
  },

  watch: {
    tab(value: string) {
      this.breadcrumbItems.pop();
      if (value === "cameras") {
        this.breadcrumbItems.push({
          text: "Cameras",
          disabled: true,
        });
      } else if (value === "nvr") {
        this.breadcrumbItems.push({
          text: "NVR's",
          disabled: true,
        });
      } else if (value === "service-node") {
        this.breadcrumbItems.push({
          text: "Service Nodes",
          disabled: true,
        });
      } else if (value === "network-devices") {
        this.breadcrumbItems.push({
          text: "Network Devices",
          disabled: true,
        });
      } else if (value === "edge-devices") {
        this.breadcrumbItems.push({
          text: "Edge Devices",
          disabled: true,
        });
      } else if (value === "digital-board") {
        this.breadcrumbItems.push({
          text: "Display Boards",
          disabled: true,
        });
      }
    },
  },
});
