















































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import {
  Alert,
  ParkingLotOnlyId,
  AzureUsers,
  CheckEscalatedAlert,
} from "@/api/models";
import AlertDetails from "@/components/AlertDetails.vue";
import CameraMapDetails from "@/components/CameraMapDetails.vue";
import api from "@/api/api";

interface AlertActionItems {
  [key: number]: string;
}

export default Vue.extend({
  name: "Alerts",

  components: {
    AlertDetails,
    CameraMapDetails,
  },

  data() {
    return {
      lotId: 0,
      anprId: 0,
      breadcrumbItems: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "Alerts",
          disabled: true,
        },
      ],
      alertActionItems: [
        { text: "Mark Inactive", value: "inactive" },
        { text: "Escalate", value: "escalate" },
        { text: "Ignore", value: "ignore" },
        { text: "Close", value: "close" },
      ],
      selectedAlertActions: {} as AlertActionItems,
      pagination: {
        page: 1,
        itemsCount: 0,
        itemsPerPage: 20,
      },
      filters: {
        searchText: "",
        severity: {
          items: ["High", "Medium", "Low"],
          selected: "",
        },
        category: {
          items: [
            "Infrastructure",
            "Violation",
            "System_Error",
            "Informational_Events",
          ],
          selected: "",
        },
        camera_ids: [] as Array<number>,
        lot_ids: [] as Array<number>,
        alert_types: [] as Array<string>,
        archived: false,
        oldestFirst: false,
        dateMenu: {
          showMenu: false,
          value: [] as Array<string>,
        },
      },
      alerts: {
        headers: [
          { text: "ID", value: "id" },
          { text: "Severity", value: "severity" },
          { text: "Title", value: "title" },
          { text: "Category", value: "category" },
          { text: "Parking Lot Name", value: "parking_lot_name" },
          { text: "Camera ID", value: "camera_id" },
          { text: "License Plate", value: "license_plate_number" },
          { text: "Timestamp", value: "created_at" },
          { text: "State", value: "alert_state" },
          { text: "Trigger State", value: "alert_trigger_state" },
        ],
        data: [] as Array<Alert>,
        selected: null as Alert | null,
        showDetailsCard: false,
        isLoading: false,
        camera_ids: [] as Array<number>,
        lot_ids: [] as Array<ParkingLotOnlyId>,
        all_alert_types: [] as Array<string>,
      },
      updated_at: null as Date | null,
      total_severity_counts: {
        high: 0,
        medium: 0,
        low: 0,
      },
      alertAction: {
        selected: 0,
        action: "",
        confirm: false,
        loading: false,
        similarExists: null as CheckEscalatedAlert | null,
        newOrUpdate: "create",
        prevIssueId: null as number | null,
      },
      newAlert: {
        show: false,
        parking_lot_id: 0,
        details: "",
        severity: "",
        category: "Admin_Generated",
      },
      forward: {
        show: false,
        alert_id: 0,
        comment: "",
      },
      azureUsers: {
        selected: "",
        items: [] as Array<AzureUsers>,
      },
      cameraMapEditor: {
        show: false,
        selectedLotId: null as number | null,
        selectedCameraId: null as number | null,
        windowSize: {
          width: 810,
          height: 500,
          minimizedWidth: 0,
          minimizedHeight: 0,
        },
      },
      superAdminViewSwitched: false,
      showActiveCameraIssues: false,
    };
  },

  created() {
    this.lotId = Number(this.$route.params.lotId);
  },

  async mounted() {
    const category = this.$route.query.category;
    if (category && category == "violation") {
      this.filters.category.selected = "Violation";
    }
    const camera_issues = this.$route.query.camera_issues;
    if (camera_issues) {
      this.showActiveCameraIssues = true;
    }

    if (this.lotId) {
      this.filters.lot_ids.push(this.lotId);
      this.getLotData(this.lotId);
    }
    const lotId = this.$route.query.lotId;
    if (lotId && !this.lotId) {
      this.filters.lot_ids.push(Number(lotId));
    }
    const anprId = this.$route.query.lpr;
    if (anprId) {
      this.anprId = Number(this.$route.query.lpr);
    }
    this.getAlertsData();

    // Fetch and display the alert_id if present in URL
    const queryAlertId = this.$route.query.alert_id;
    if (queryAlertId) {
      this.getAlertData(Number(queryAlertId));
    }

    if (this.isSuperAdminViewSwitched) {
      this.superAdminViewSwitched = true;
    }
    if (this.isSuperAdmin) {
      this.alerts.headers = this.alerts.headers.filter(
        (header) => header.value != "license_plate_number"
      );
    }
  },

  beforeUpdate() {
    if (this.isSuperAdmin) {
      // if superadmin then allow alerts to be forwarded to customers
      if (!this.alerts.headers.find((a) => a.value == "forward")) {
        this.alerts.headers.push({ text: "Forward", value: "forward" });
      }
      if (!this.alertActionItems.find((a) => a.value == "escalate")) {
        this.alertActionItems.unshift({ text: "Escalate", value: "escalate" });
      }
    } else {
      this.alertActionItems = this.alertActionItems.filter(
        (a) => a.value != "escalate"
      );
    }
    if (this.lotId) {
      this.newAlert.parking_lot_id = this.lotId;
    }
  },

  computed: {
    ...mapGetters("user", [
      "isSuperAdmin",
      "isAdmin",
      "isSuperAdminViewSwitched",
      "hasAccessLevelDashboardMonitoring",
    ]),
    validateGenerateAlert() {
      if (
        this.newAlert.parking_lot_id &&
        this.newAlert.severity &&
        this.newAlert.category &&
        this.newAlert.details &&
        this.newAlert.details.length <= 512
      ) {
        return false;
      }
      return true;
    },
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),

    async getLotData(lot_id: number | null = null) {
      if (lot_id) {
        let parkingLotData = await api.getParkingLot(lot_id);
        this.initCurrentParkingLotData(parkingLotData);

        if (parkingLotData) {
          if (!parkingLotData.is_anpr_feature_visible_to_customers) {
            this.alerts.headers = this.alerts.headers.filter(
              (header) => header.value != "license_plate_number"
            );
          }
        }
      }
    },

    async getAlertData(alertOrId: number | Alert) {
      if (this.isSuperAdminViewSwitched) {
        this.superAdminViewSwitched = true;
      }
      let queryAlertId;
      if (typeof alertOrId === "object") {
        queryAlertId = alertOrId.id;
      } else {
        queryAlertId = alertOrId;
      }
      this.alerts.isLoading = true;
      try {
        let alert = await api.getAlert(
          queryAlertId,
          this.superAdminViewSwitched
        );
        if (alert) {
          this.showAlert(alert);
        }
      } catch (error) {
        // if user does not have access to the alert
        if (error.response.status === 403) {
          this.$dialog.message.error(error.response.data.detail, {
            position: "top-right",
            timeout: 3000,
          });
          this.alerts.isLoading = false;

          setTimeout(() => {
            this.$dialog.message.info("Loading all Alerts", {
              position: "top-right",
              timeout: 3000,
            });
          }, 3000);
          this.getAlertsData();
          return;
        }
      }
      this.alerts.isLoading = false;
    },

    async getAlertsData() {
      if (this.isSuperAdminViewSwitched) {
        this.superAdminViewSwitched = true;
      }
      this.setFilterOptions();
      this.alerts.isLoading = true;
      let alertsData = await api.getAllAlerts(
        this.filters.searchText.trim(),
        this.filters.severity.selected.toLowerCase(),
        this.filters.category.selected.toLowerCase(),
        this.filters.archived,
        this.filters.oldestFirst,
        this.filters.lot_ids,
        this.filters.camera_ids,
        this.filters.alert_types,
        this.filters.dateMenu.value,
        this.anprId != 0 ? this.anprId : null,
        this.superAdminViewSwitched,
        this.showActiveCameraIssues,
        this.pagination.page,
        this.pagination.itemsPerPage
      );
      this.updated_at = new Date();
      if (alertsData && alertsData.all_alerts) {
        this.alerts.data = alertsData.all_alerts.items;
        this.pagination.itemsCount = alertsData.all_alerts.total;

        // if current page number is greater than total pages, then reset to last page
        if (
          this.pagination.page >
          Math.ceil(this.pagination.itemsCount / this.pagination.itemsPerPage)
        ) {
          this.pagination.page =
            this.pagination.itemsCount > 0
              ? Math.ceil(
                  this.pagination.itemsCount / this.pagination.itemsPerPage
                )
              : 1;
        }

        this.total_severity_counts.high = alertsData.high_severity_count;
        this.total_severity_counts.medium = alertsData.medium_severity_count;
        this.total_severity_counts.low = alertsData.low_severity_count;

        if (alertsData.azure_users_list) {
          this.azureUsers.items = alertsData.azure_users_list;
        }

        if (alertsData.all_lot_ids)
          this.alerts.lot_ids = alertsData.all_lot_ids;
        if (alertsData.all_camera_ids)
          this.alerts.camera_ids = alertsData.all_camera_ids.map(
            (camera) => camera.id
          );
        if (alertsData.all_alert_types) {
          this.alerts.all_alert_types = alertsData.all_alert_types
            .filter((alert) => {
              if (
                this.filters.severity.selected.toLowerCase() == "" &&
                this.filters.category.selected.toLowerCase() == ""
              )
                return true;
              if (
                this.filters.severity.selected.toLowerCase() === alert.severity
              )
                return true;
              if (
                this.filters.category.selected.toLowerCase() === alert.category
              )
                return true;
              return false;
            })
            .map((alert) => alert.title);
        }
      }
      if (!this.alerts.headers.find((a) => a.value == "alert_action")) {
        if (this.hasAccessLevelDashboardMonitoring) {
          this.alerts.headers.push({
            text: "Take Action",
            value: "alert_action",
          });
        }
      }
      this.alerts.isLoading = false;
    },

    filterByDates() {
      if (
        this.filters.dateMenu.value &&
        this.filters.dateMenu.value.length >= 2
      ) {
        this.getAlertsData();
        this.filters.dateMenu.showMenu = false;
      }
    },

    clearDates() {
      this.filters.dateMenu.showMenu = false;
      this.filters.dateMenu.value = [];
      this.getAlertsData();
    },

    showAlert(alert: Alert) {
      console.log("Showing alert", alert);
      this.alerts.selected = alert;
      this.alerts.showDetailsCard = true;
    },

    closedAlert() {
      this.alerts.selected = null;
      this.alerts.showDetailsCard = false;
      localStorage.removeItem("refreshPage");
    },

    resetFilters() {
      this.filters.searchText = "";
      this.filters.severity.selected = "";
      this.filters.category.selected = "";
      this.filters.archived = false;
      this.filters.oldestFirst = false;
      this.filters.lot_ids = [];
      this.filters.camera_ids = [];
      this.filters.alert_types = [];
      this.filters.dateMenu.value = [];
      this.filters.dateMenu.showMenu = false;
      if (this.lotId) {
        this.filters.lot_ids.push(this.lotId);
      }
      this.getAlertsData();
    },

    getSeverityColor(severity: string, alert_type_id: number | null) {
      if (alert_type_id && [1, 9, 21, 28, 29, 43].includes(alert_type_id)) {
        return "green";
      }
      if (severity === "high") return "red";
      else if (severity === "medium") return "orange";
      else return "yellow darken-2";
    },

    getSeverityIcon(severity: string) {
      if (severity === "high") return " mdi-alert-circle";
      else if (severity === "medium") return " mdi-alert";
      else return " mdi-alert-box";
    },

    async archiveAlert(alert: Alert) {
      if (alert.archived_at == null) {
        this.$dialog.message.info(`Adding Alert ${alert.id} to Archived`, {
          position: "top-right",
          timeout: 3000,
        });
      } else {
        this.$dialog.message.info(`Removing Alert ${alert.id} from Archived`, {
          position: "top-right",
          timeout: 3000,
        });
      }

      this.alerts.isLoading = true;
      let res = await api.archiveUnarchiveAlert(
        alert.id,
        !this.filters.archived
      );
      this.getAlertsData();
    },

    forwardAlert(alert: Alert) {
      this.forward.show = true;
      this.forward.alert_id = alert.id;
    },

    async forwardAlertReq() {
      this.forward.show = false;
      this.alerts.isLoading = true;
      let alert = await api.forwardAlert(
        this.forward.alert_id,
        this.forward.comment
      );
      if (alert) {
        this.$dialog.message.info("Alert forwarded sucessfully.", {
          position: "top-right",
          timeout: 3000,
        });
        this.closeForwardAlert();
        this.getAlertsData();
      } else {
        this.$dialog.message.error(
          "Unable to forward the Alert. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        this.forward.show = true;
      }
      this.alerts.isLoading = false;
    },

    sortAlertsByTimestamp() {
      this.filters.oldestFirst = !this.filters.oldestFirst;
      this.getAlertsData();
    },

    setFilterOptions() {
      switch (this.filters.severity.selected.toLowerCase()) {
        case "high":
          this.filters.category.items = [
            "Infrastructure",
            "System_Error",
            "Informational_Events",
          ];
          break;
        case "medium":
          this.filters.category.items = ["Violation", "Informational_Events"];
          break;
        case "low":
          this.filters.category.items = [
            "Infrastructure",
            "Violation",
            "Informational_Events",
          ];
          break;
        default:
          this.filters.category.items = [
            "Infrastructure",
            "Violation",
            "System_Error",
            "Informational_Events",
          ];
      }
      switch (this.filters.category.selected.toLowerCase()) {
        case "infrastructure":
          this.filters.severity.items = ["High", "Low"];
          break;
        case "violation":
          this.filters.severity.items = ["Medium", "Low"];
          break;
        case "system_error":
          this.filters.severity.items = ["High"];
          break;
        default:
          this.filters.severity.items = ["High", "Medium", "Low"];
      }

      if (this.isSuperAdmin) {
        this.filters.category.items = this.filters.category.items.filter(
          (category) =>
            category != "Violation" && category != "Informational_Events"
        );
      }
    },

    setAlertAction(alert_id: number) {
      this.alertAction.selected = alert_id;
    },

    async checkIfSimilarAlertEscalated(alert_id: number) {
      this.alertAction.loading = true;
      this.alertAction.similarExists = await api.checkAlertEscalated(alert_id);
      this.alertAction.loading = false;
    },

    takeAlertAction(evt: Event) {
      this.alertAction.action = `${evt}`;
      this.alertAction.confirm = true;
      if (this.alertAction.action === "escalate" && this.alertAction.selected) {
        this.checkIfSimilarAlertEscalated(this.alertAction.selected);
      }
    },

    async sendAlertAction() {
      this.alertAction.confirm = false;
      this.alerts.isLoading = true;
      try {
        const alert = await api.takeAlertAction(
          this.alertAction.selected,
          this.alertAction.action,
          this.azureUsers.selected,
          this.alertAction.newOrUpdate,
          this.alertAction.prevIssueId
        );
        if (alert) {
          this.getAlertsData();
        } else {
          this.$dialog.message.error(
            `Error, unable to ${this.alertAction.action} alert ${this.alertAction.selected}. Please try again later.`,
            {
              position: "top-right",
              timeout: 3000,
            }
          );
          this.alerts.isLoading = false;
        }
      } catch (error) {
        this.$dialog.message.error(error.response.data.detail, {
          position: "top-right",
          timeout: 3000,
        });
        this.alerts.isLoading = false;
        return;
      }
      this.closeAlertAction();
    },

    closeAlertAction() {
      for (let key in this.selectedAlertActions) {
        this.selectedAlertActions[key] = "";
      }
      this.alertAction.confirm = false;
      this.alertAction.action = "";
      this.alertAction.selected = 0;
      this.alertAction.loading = false;
      this.alertAction.similarExists = null;
      this.alertAction.newOrUpdate = "create";
      this.azureUsers.selected = "";
      this.alertAction.prevIssueId = null;
    },

    closeForwardAlert() {
      this.forward.alert_id = 0;
      this.forward.show = false;
      this.forward.comment = "";
    },

    async generateAlert() {
      let alertDetails = {
        parking_lot_id: this.newAlert.parking_lot_id,
        severity: this.newAlert.severity.toLowerCase(),
        category: this.newAlert.category.toLowerCase(),
        details: this.newAlert.details,
      };
      let alert = await api.generateAlert(alertDetails);
      if (alert) {
        this.$dialog.message.info("Custom Alert generated sucessfully.", {
          position: "top-right",
          timeout: 3000,
        });
        this.closeGenerateAlert();
      } else {
        this.$dialog.message.error(
          "Unable to generate the Alert. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    closeGenerateAlert() {
      this.newAlert.parking_lot_id = 0;
      this.newAlert.severity = "";
      this.newAlert.details = "";
      this.newAlert.show = false;
    },

    editProposedCameraMap(lotId: number, cameraId: number) {
      this.cameraMapEditor.show = true;
      this.cameraMapEditor.selectedLotId = lotId;
      this.cameraMapEditor.selectedCameraId = cameraId;
    },

    // convert 24 hour time to 12 hour time format
    timeConvert(time: string) {
      return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
    },
    openAllxonAlertsDashboard() {
      window.open("https://dms.allxon.com/dashboard", "_blank");
    },
  },

  watch: {
    "filters.searchText"() {
      if (this.filters.searchText && this.filters.searchText.length > 3) {
        this.getAlertsData();
      }
    },
    "alertAction.confirm"(value) {
      if (!value) {
        this.closeAlertAction();
      }
    },
    "filters.archived"(value) {
      if (value) {
        // this.alerts.headers = [
        //   ...this.alerts.headers.filter(
        //     (a) => a.value != "alert_action" && a.value != "forward"
        //   ),
        // ];
        this.alerts.headers.push({ text: "Archived", value: "archived" });
      } else {
        this.alerts.headers = [
          ...this.alerts.headers.filter((a) => a.value != "archived"),
        ];
        // if (this.hasAccessLevelDashboardMonitoring) {
        //   this.alerts.headers.push({
        //     text: "Take Action",
        //     value: "alert_action",
        //   });
        // }
        // if (this.isSuperAdmin) {
        //   this.alerts.headers.push({ text: "Forward", value: "forward" });
        // }
      }
    },
  },
});
